import React, { useState, useEffect } from "react";
import RootLayout from "../../component/layout";
import Element from "../../component/stripe/element";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getModulePublicKey, getPayment } from "../../network/api";
import Easebuzz from "../../component/easebuzz/Form";
import Awaiting from "../../component/common/Awaiting";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePayment,
} from "../../redux/actions/customerActions";
import { getPortalConfig } from "../../redux/actions/portalActions";
import { getMudrexKycLink, getMudrexUserKyc } from "../../redux/actions/mudrexActions";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [config, setConfig] = useState({});
  const [publicKey, setPublicKey] = useState();
  const [isConfigured, setIsConfigured] = useState(false);

  const checkoutConfig = useSelector((state) => state?.reducer?.checkoutConfig);

  const fetchPortalConfig = async (key) => {
    await dispatch(getPortalConfig(checkoutConfig?.keyUsed || key));
  };

  const handleMudrexPayment = async (paymentId, key) => {

    const response = await dispatch(getMudrexUserKyc(paymentId, key));

    if (response.data.kyc_status == "Verified") {
      navigate("/mudrex/payment/methods");
    } else {
      const response = await dispatch(getMudrexKycLink(paymentId, key));

      if (response.code === 1) {
        window.location.assign(response.data);
      }
    }

  }



  useEffect(() => {
    let searchObject = location.state;
    const fetchData = async () => {
      if (!searchObject) {

        // If there's no location state, use the existing checkoutConfig
        searchObject = checkoutConfig;
      }
      setConfig(searchObject);
      !searchObject?.customerId && await dispatch(updatePayment({}, searchObject?._id));
      if (!searchObject?.keyUsed) {
        navigate("/error/config");
        return;
      }

      switch (searchObject?.provider) {
        case "stripe":
        case "easebuzz":
          const resKey = await getModulePublicKey(
            searchObject?.keyUsed,
            searchObject?.provider
          );
          if (!resKey?.key) {
            navigate("/error/config");
            return;
          }
          setPublicKey(resKey.key);
          setIsConfigured(true);
          break;
        case "wyrapay":
        case "triple-a":
        case "banksy":
        case "mudrex":
          setIsConfigured(true);
          break;
        default:
          break;
      }
    };

    fetchData();
    fetchPortalConfig(searchObject?.keyUsed);
  }, [location.state, navigate]);

  const renderCheckout = () => {
    switch (config?.provider) {
      case "stripe":
        return (
          <Element
            apiKey={config?.keyUsed}
            stripeKey={publicKey}
            clientSecret={config?.meta?.client_secret}
            paymentId={config?._id}
            payment={config}
            theme={config?.theme === "dark" ? "night" : "stripe"}
          />
        );
      case "wyrapay":
        window.location.assign(config?.meta?.redirect_url);
        break;
      case "triple-a":
        window.location.assign(config?.meta?.redirect_url);
        break;
      case "mudrex":
        handleMudrexPayment(config?._id, config?.keyUsed);
        break;
      case "banksy":
        navigate("/checkout/crypto");
        break;
      case "easebuzz":
        return (
          <Easebuzz
            apiKey={config?.keyUsed}
            publicKey={publicKey}
            accessToken={config?.meta?.page_access_token}
            paymentId={config?._id}
            payment={config}
            theme={config?.theme === "dark" ? "night" : "stripe"}
          />
        );
      default:
        return null;
    }
  };
  return (
    <RootLayout
      title="Summary" onBackClick={() => window.back()}>
      {isConfigured ? (
        renderCheckout()
      ) : (
        <Awaiting />
      )}
    </RootLayout>
  );
};

export default Checkout;
