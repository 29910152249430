import accounting from "accounting";
import Button from "../common/Button";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ConfirmationCrypto = () => {
    const socket = useSelector(state => state.socket);
    const paymentObj = useSelector(state => state?.reducer?.checkoutConfig);
    const [countdown, setCountdown] = useState(null);

    useEffect(() => {
        // Start countdown when the first message status becomes "success"
        if (socket?.messages[0]?.status === "success" && countdown === null) {
            setCountdown(5);
        }
    }, [socket?.messages, countdown]);

    useEffect(() => {
        if (countdown !== null && countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else if (countdown === 0) {
            window.location.assign(paymentObj?.successCallback + "?paymentId=" + paymentObj?._id);
        }
    }, [countdown, paymentObj]);

    return (
        <div className="w-full mt-8">
            <div className="transactions-box rounded-xl border border-gray pt-6 pb-[12px] px-4 w-full">
                <h3 className="common-lbl fw-bold">Transactions</h3>
                <div className="w-full">
                    {socket?.messages.map((message, index) => (
                        message?.status === "success" ? (
                            <div key={index} className="flex items-center justify-between py-4 border-b border-gray trx-row">
                                <div className="flex items-center justify-start">
                                    <span className="common-lbl">
                                        {accounting.formatNumber(message?.amount, { precision: 2 })} {message?.currency}
                                    </span>
                                    <span className="w-[50px] h-[12px] flex items-center justify-center small-text-lbl bg-green text-white uppercase rounded-xl ml-4">{message?.status}</span>
                                </div>
                                <div className="flex items-end justify-end">
                                    <span className="small-text-lbl">{message?.confirmationCount - 1}/{paymentObj?.crypto?.confirmationsRequired || "12"} Confirmations</span>
                                </div>
                            </div>
                        ) : (
                            <div key={index} className="flex items-center justify-between py-4 border-b border-gray trx-row">
                                <div className="flex items-center justify-start">
                                    <span className="common-lbl">
                                        {accounting.formatNumber(message?.amount, { precision: 2 })} {message?.currency}
                                    </span>
                                    <span className="w-[50px] h-[12px] flex items-center justify-center small-text-lbl bg-orange text-white uppercase rounded-xl ml-4">{message?.status}</span>
                                </div>
                                <div className="flex items-end justify-end">
                                    <span className="small-text-lbl">{message?.confirmationCount-1}/{paymentObj?.crypto?.confirmationsRequired || "12"} Confirmations</span>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
            {socket?.messages[0]?.status === "success" ? (
                <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
                    <Button
                        disabled={true}
                    >
                        <span className="text-white fw-bold" > {countdown !== null
                            ? `Redirecting in ${countdown}...`
                            : "Redirecting to Site"}</span>
                    </Button>
                </div>
            ) : (
                <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
                    <Button
                        className="!flex items-center justify-center" disabled={true}>
                        <div className="circle-spin-loader btn-small-spinner"></div>
                        <span>Waiting Confirmations</span>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ConfirmationCrypto;