import React from "react";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";

const ErrorPage = ({
  imageSource,
  title,
  description,
  actionLink,
  actionText
}) => {
  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <div className="flex justify-center">
        <img src={imageSource} alt="error" className="w-40" />
      </div>
      <Title level={1} className="text-center mt-[40px] !mb-0 common-lbl text-lbl-para fw-bold font-large">
        {title}
      </Title>
      <div className="w-[70px] h-[1px] mx-auto my-[25px] bg-line-gray"></div>
      <Paragraph className="text-center common-lbl !mb-0">
        {description}
        {actionLink && actionText && (
          <>
            {" "}
            <a className="text-underline" href={actionLink}>
              {actionText}
            </a>
          </>
        )}
      </Paragraph>
    </div>
  );
};

export default ErrorPage;