import React, { useState, useEffect } from "react";
import { customerDetails } from "../../redux/actions/customerActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../common/Button";
// import { checkKycStatus } from "../../redux/actions/customerActions"; // Assume this action exists

const KycVerificationStatus = () => {
  const [status, setStatus] = useState("awaiting");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkStatus = async () => {
    setLoading(true);
    // Simulate API call
    setTimeout(async () => {
      // Replace this with actual API call when ready
      const response = await dispatch(customerDetails());
      setStatus(
        response.data.kycStatus == "approved"
          ? "success"
          : response.data.kycStatus == "failed"
            ? "failure"
            : "awaiting"
      );
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    checkStatus();
    let intervalId;
    if (status === "awaiting") {
      intervalId = setInterval(checkStatus, 10000); // Check every 10 seconds
    }
    return () => clearInterval(intervalId);
  }, [status]);

  const handleButtonClick = () => {
    switch (status) {
      case "awaiting":
        checkStatus();
        break;
      case "success":
        navigate("/checkout");
        break;
      case "failure":
        navigate("/auth/kyc");
        break;
    }
  };

  const renderContent = () => {
    switch (status) {
      case "awaiting":
        return (
          <>
            <div className="w-[70px] h-[1px] mx-auto my-[20px] bg-line-gray"></div>
            <p className="common-lbl text-center mb-[20px]">
              Your KYC is under process. <br /> It will be verified soon.
            </p>
            {/* <svg className="w-16 h-16 text-blue-500 mb-4 animate-spin" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg> */}
            <div className="circle-spin-loader"></div>
            <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
              <Button onClick={handleButtonClick} loading={loading}>
                Refresh Status
              </Button>
            </div>
          </>
        );
      case "success":
        return (
          <>
            <div className="w-[70px] h-[1px] mx-auto my-[20px] bg-line-gray"></div>
            <p className="common-lbl text-center mb-[20px]">
              KYC verification completed successfully!
            </p>
            <svg
              className="w-16 h-16 text-green mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
              <Button onClick={handleButtonClick}>Proceed to Checkout</Button>
            </div>
          </>
        );
      case "failure":
        return (
          <>
            <div className="w-[70px] h-[1px] mx-auto my-[20px] bg-line-gray"></div>
            <p className="common-lbl text-center mb-[20px]">
              KYC verification rejected. <br /> Please try again.
            </p>
            <svg
              className="w-16 h-16 text-red mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
              <Button onClick={handleButtonClick}>Re-initiate KYC</Button>
            </div>
          </>
        );
    }
  };
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="bnksy-page-title">
        KYC <br /> Verification Status
      </h1>
      {renderContent()}
    </div>
  );
};

export default KycVerificationStatus;
