import React, { useState, useEffect } from "react";
import RootLayout from "../../../component/layout";
import { useLocation } from "react-router-dom";
import ErrorPage from "../../../component/common/Error";

const Timeout = () => {
  const location = useLocation();

  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig(location.state);
  }, [location.state]);

  return (
    <RootLayout>
      <ErrorPage
      imageSource="/timeup.svg"
      title="Oops! Transaction timeout."
      description="Please return"
      actionLink={`${config?.failureCallback}?paymentId=${config?._id}`}
      actionText="back to website"
    />
    </RootLayout>
  );
};

export default Timeout;
