import jwt from 'jsonwebtoken';

const publicKey = process.env.REACT_APP_JWT_KEY || '';

// Function to convert base64 to ArrayBuffer


export const verifyToken = (token) => {
  try {
    const pubkey = atob(publicKey);
    const payload = jwt.verify(token, pubkey, { algorithms: ['RS256'] });
    return payload;
  } catch (error) {
    console.error('Error verifying token:', error);
    throw error;
  }
};