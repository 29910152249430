import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { addMessage, addStatus } from '../reducer/socketSlice';

const useSocket = () => {

  const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

  const keyUsed = useSelector((state) => state?.reducer?.checkoutConfig?.keyUsed);
  const customerId = useSelector((state) => state?.reducer?.checkoutConfig?.customerId) ;

  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io(SOCKET_URL, {
      reconnectionDelayMax: 10000,
      auth: {
        token: keyUsed,
      },
      query: {
        "customerId": customerId?._id
      }
    });

    socket.on("CLIENT_RECEIVE_CONFIRMATION", (args) => {
      dispatch(addMessage(args));
    });

    const socketStatus = socket.active
    dispatch(addStatus(socketStatus));

    

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);
};

export default useSocket;