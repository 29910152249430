import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "../../../component/layout";
import { verifyPaymentIntent } from "../../../network/api";
import Awaiting from "../../../component/common/Awaiting";

const StripeSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const searchObject = {};
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of searchParams.entries()) {
        searchObject[key] = value;
      }
      if (!searchObject.hasOwnProperty("payment_intent")) {
        navigate("/error/config");
      } else {
        const status = await verifyPaymentIntent(
          searchObject.apiKey,
          searchObject.paymentId,
          searchObject?.payment_intent
        );
        if (status?.status === "success") {
          window.location.assign(
            status.successCallback + "?paymentId=" + searchObject.paymentId
          );
        } else {
          window.location.assign(
            status.failureCallback + "?paymentId=" + searchObject.paymentId
          );
        }
      }
    };
    fetchData();
  }, [location.search, navigate]);
  return (
    <RootLayout>
      <Awaiting/>
    </RootLayout>
  );
};

export default StripeSuccess;
