import React, { Fragment, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { customerAuth } from "../../redux/actions/customerActions";
import { useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import Button from "../common/Button";

const Signin = () => {
  const [inputType, setInputType] = useState("email");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // // Custom styles for PhoneInput
  const phoneInputStyle = {
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "0.375rem",
    color: "white",
    fontSize: "1rem",
    width: "100%",
    height: "100%",
  };

  const phoneContainerStyle = {};

  const phoneDropdownStyle = {
    backgroundColor: "white",
    color: "black",
  };

  const flagButtonStyle = {
    backgroundColor: "transparent",
    border: "none",
    borderRight: "1px solid transparent",
  };

  const phoneDropdownCountryStyle = {
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  };

  const phoneHighlightStyle = {
    backgroundColor: "white",
    color: "black",
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value.trim());
    setError("");
  };

  const handlePhoneChange = (value) => {
    setInputValue(value);
    setError("");
  };

  const toggleInputType = (type) => {
    setInputType(type);
    setInputValue("");
    setError("");
  };

  const handleOTP = async () => {
    if (!inputValue) {
      setError(
        `Please enter ${inputType === "email" ? "email" : "phone number"}.`
      );
      return;
    }
    setLoading(true);
    const response = await dispatch(customerAuth({ to: inputValue }));

    if (response.code === 1) {
      navigate("/auth/otp");
    } else {
      showErrorMessage(response.data);
    }
    setLoading(false);
  };

  const showErrorMessage = (errMessage) =>
    toast.error(errMessage, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      theme: "colored",
      transition: Bounce,
    });

  return (
    <Fragment>
      <div className="w-full flex flex-col items-center justify-start pt-[97px]">
        <a href="/main/dashboard/" className="logo-link">
          <img
            src="/banksy-black-logo.svg"
            alt="logo"
            className="w-full logo-transparent-white"
          />
        </a>
        <p className="bnksy-page-title mt-8 mb-[12px]">Sign in </p>

        {/* New tab-like button */}
        <div className="flex mb-5 bnksy-tabs-box">
          <button
            className={`px-7 py-2.5 rounded-full common-lbl ${inputType === "email" ? "tab-active" : ""
              }`}
            onClick={() => toggleInputType("email")}
          >
            Email
          </button>
          <button
            className={`px-7 py-2.5 rounded-full common-lbl ${inputType === "phone" ? "tab-active" : ""
              }`}
            onClick={() => toggleInputType("phone")}
          >
            Phone
          </button>
        </div>

        <div className="w-full">
          <div className="w-full bnksy-input-group mb-4">
            {inputType === "email" ? (
              <input
                type="email"
                className="bnksy-form-input"
                id="signup-input"
                placeholder="Email address"
                value={inputValue}
                onChange={handleInputChange}
                required
              />
            ) : (
              <PhoneInput
                country={"us"}
                value={inputValue}
                onChange={handlePhoneChange}
                inputProps={{
                  id: "signup-input",
                  required: true,
                  placeholder: "Phone Number",
                  className: "bnksy-form-input !pl-[50px]",
                }}
                inputStyle={phoneInputStyle}
                containerStyle={phoneContainerStyle}
                dropdownStyle={phoneDropdownStyle}
                buttonStyle={flagButtonStyle}
                dropdownCountryStyle={phoneDropdownCountryStyle}
                highlightStyle={phoneHighlightStyle}
              />
            )}
            {error && (
              <div className="text-red-600 font-regular mt-2">{error}</div>
            )}
          </div>

          <div className="w-full fixed bottom-0 left-0 px-[20px] py-[22px]">
            <Button
              onClick={handleOTP}
              disabled={!inputValue}
              loading={loading}
            >
              Send OTP
            </Button>
          </div>
          <ToastContainer position="bottom-right" />
        </div>
      </div>
    </Fragment>
  );
};

export default Signin;
